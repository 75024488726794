import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AnzeigeAlternative from "../images/Fa.Hengel_Image2_2025.png"

const useStyles = makeStyles((theme) => ({
    wrapper: {
      margin: "150px auto",
      display: "flex",
      backgroundColor: "#F3EEEB",

    },
    innerWrapper: {
      width: "90vw",
      margin: "40px auto",
      [theme.breakpoints.down("md")]: {
        margin: "40px auto 60px auto",
      },

    },
    image: {
      width: "100%",
    },
    imageAlternative: {
      width: "50%",
      padding: "10px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    item: {
      padding: "40px 80px",
      display: "flex",
      alignSelf: "center",
      [theme.breakpoints.down("md")]: {
        display: "block",
        padding: "5px",
      },
    },
    leftItem: {
      [theme.breakpoints.up("lg")]: {
        borderRight: "1px solid #666666"
      },
    }
}));

export default function TextBildZwei(props) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.wrapper}>
      <Grid container className={classes.innerWrapper}>
        <Grid item xs={12} md={4} className={`${classes.item} ${classes.leftItem}`}  >
          <h2 >{props.heading}</h2>
        </Grid>
  {/*       <Grid item xs={12} md={6} className={classes.item}>
          <img src={props.url} alt={props.alt} className={classes.image}/>

        </Grid> */}
        <Grid item xs={12} md={6} className={classes.item}>
          <img src={props.url} alt={props.alt} className={classes.imageAlternative} />
          <img src={AnzeigeAlternative} className={classes.imageAlternative} alt="Anzeige"  />
        </Grid>
        </Grid>
      </Grid>
    </>
  );
}
