import React, { Component } from "react";
import "../App.css";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { gsap, Power1 } from "gsap";

import Layout from "../components/layout/layout";
import HeroNavbar from "../components/heronavbar";
import BildTextZwei from "../components/bildTextZwei";
import TextBildZwei from "../components/textBildZwei";
import Leistung from "../components/leistung";
import TextZwei from "../components/textZwei";
import ButtonBlank from "../components/layout/buttonBlank";

//Data
import Logo from "../images/Ueber100.png";
import Generation from "../images/Kurt_Simon.webp";
import Team from "../images/Team_MitarbeiterBlau.webp";
import Heizung from "../images/Icon Heizungsbau.png";
import Sanitaer from "../images/Icon Sanitär.png";
import Energien from "../images/Icon Erneuerbare Energien.png";
import Planung from "../images/Icon Badkonzepte.png";
import Anzeige from "../images/Anzeige.png"
import AnzeigeAlternative from "../images/Fa.Hengel_Image2_2025.png"
import home from "../data/home.json";
import leistung from "../data/leistungen.json";


import KurtHengel from "../images/KurtHengel.jpg";
import SimonSchädlich from "../images/SimonSchädlich.jpg";
import DimitriosMarkou from "../images/DimitriosMarkou.jpg";
import UweEbert from "../images/UweEbert.jpg";
import NikoShaka from "../images/NikoShaka.jpg";
import AnastasiosThomaidis from "../images/AnastasiosThomaidis.jpg";
import AthanasiosMandratzis from "../images/AthanasiosMandratzis.jpg";
import TimEmmrich from "../images/TimEmmrich.jpg";
import BrittaKammerer from "../images/BrittaKammerer.jpg";

const HeroContent = styled.div`
  position: absolute;
  width: 90vw;
  text-align: center;
  padding: 0px 10px 20px;
`;

const HeroText = styled.h3`
  max-width: 500px;
  margin: 20px auto;
  font-weight: 100;
`;

const TextContainer = styled.div`
  padding: 0px 30px 50px 30px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 0px 30px 10px 30px;
  }
`;

const LeistungContainer = styled.div`
  width: 90vw;
  max-width: 1400px;
  margin: 0px auto 150px auto;
  padding: 10px;
`;

const ImageContainer = styled.div`
  width: 90vw;
  max-width: 1400px;
  margin: 0px auto 150px auto;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  @media only screen and (max-width: 767px){
    display: block;
  }

`;
class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    let container = document.querySelector(".content_container");

    let tl = gsap.timeline();
    tl.to(container, { duration: 0, opacity: 0, y: 30 }).to(container, {
      duration: 0.8,
      opacity: 1,
      y: 0,
      ease: Power1.easeOut,
    });
  }
  render() {
    return (
      <Layout
        pageTitle="Firma Hengel"
        description="Seit 4 Generationen verbinden wir traditionsreiche Handwerkskunst mit zukunftsträchtigen Visionen.      "
      >
        <div>
          <div className="hero--background" alt="Hengel Heizung">
            <HeroContent>
              <img
                src={Logo}
                alt="100 Jahre Firma Hengel"
                style={{ maxWidth: "60vw" }}
              />
              <HeroText>
                Seit 4 Generationen verbinden wir traditionsreiche
                Handwerkskunst mit zukunftsträchtigen Visionen.
              </HeroText>
            </HeroContent>
          </div>
          <HeroNavbar />
          <TextContainer>
          {/*   <h2 style={{ color: "#E90014" }}>Wir sind dieses Jahr für Sie bis zum 20. Dezember 2024 da. <br/> Wir freuen uns ab dem 07. Januar 2025 wieder für Sie im Einsatz zu sein.</h2>
          <h5>In dringenden Notfällen wenden sie sich an die Notfallnummer unter <a href="tel:01739625813" style={{ color: "#E90014" }}>01739625813</a>.</h5> */}
          </TextContainer>
          <BildTextZwei
            url={Generation}
            alt={home.Content[0].heading}
            heading={home.Content[0].heading}
            text={home.Content[0].text}
            button={home.Content[0].button}
            slug={home.Content[0].link}
          />

          <TextContainer>
            <h4>Das innovative Hengel-Rundumpaket von A bis Z</h4>
            <h2>Unsere Leistungen</h2>
          </TextContainer>
          <LeistungContainer>
            <Grid container spacing={8}>
              {leistung.Leistungen.map((item) => (
                <Grid item md={3}>
                  <Leistung
                    icon={
                      item.heading === "Heizungsbau"
                        ? Heizung
                        : item.heading === "Sanitär"
                        ? Sanitaer
                        : item.heading === "Erneuerbare Energien"
                        ? Energien
                        : item.heading === "Badkonzepte" &&
                          Planung
                    }
                    header={item.heading}
                    text={item.text}
                    slug={item.slug}
                  />
                </Grid>
              ))}
            </Grid>
          </LeistungContainer>
          <TextContainer>
          {" "}
          <h2>Jetzt Fördermittel entdecken</h2>
          <p>Schalten Sie auf erneuerbare Energien um und sparen Sie dabei Geld. <br/> Sowohl im Neubau als auch bei Sanierungen locken attraktive Zuschüsse. <br/> Die Förderauskunft der GC FinanzBox bietet einen umfassenden Überblick über die verfügbaren Förderprogramme.</p>
          <ButtonBlank link={"https://www.gc-gruppe.de/service/gc-foerderdatenbank"} text="Fördermitteldatenbank" />
        </TextContainer>
        
          <TextBildZwei heading="Neues aus dem Hause Hengel." url={Anzeige} />

          <TextContainer>
            <h4>Ein Team - alles aus einer Hand.</h4>
          </TextContainer>
          <BildTextZwei
            url={Team}
            alt={home.Content[1].heading}
            heading={home.Content[1].heading}
            text={home.Content[1].text}
            button={home.Content[1].button}
            slug={home.Content[1].link}
          />
          <Grid container style={{ display: "flex", justifyContent: "space-between" }}>           
             <Grid item xs={4} sm={1}><img  src={DimitriosMarkou} alt="Team Hengel" style={{ width: "100%" }} /></Grid>
             <Grid item xs={4} sm={1}><img src={UweEbert} alt="Team Hengel" style={{ width: "100%" }} /></Grid>
             <Grid item xs={4} sm={1}><img src={NikoShaka} alt="Team Hengel" style={{ width: "100%" }} /></Grid>
             <Grid item xs={4} sm={1}><img src={AnastasiosThomaidis} alt="Team Hengel" style={{ width: "100%" }} /></Grid>
             <Grid item xs={4} sm={1}><img src={TimEmmrich} alt="Team Hengel" style={{ width: "100%" }} /></Grid>
             <Grid item xs={4} sm={1}><img src={KurtHengel} alt="Team Hengel" style={{ width: "100%" }} /></Grid>
             <Grid item xs={4} sm={1}><img src={SimonSchädlich} alt="Team Hengel" style={{ width: "100%" }} /></Grid>
             <Grid item xs={4} sm={1}><img src={AthanasiosMandratzis} alt="Team Hengel" style={{ width: "100%" }} /></Grid>
             <Grid item xs={4} sm={1}><img src={BrittaKammerer} alt="Team Hengel" style={{ width: "100%" }} /></Grid>

            </Grid>
          <TextZwei
            heading={home.heading}
            text={home.text}
            button={home.button}
            slug={home.link}
          />
        </div>
      </Layout>
    );
  }
}

export default Home;
